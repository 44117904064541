<script>
// import { SunriseIcon, ArrowRightIcon } from "vue-feather-icons";

// import Navbar from "@/components/navbar";
// import Services from "@/components/services";
import GetSercies from "@/components/getservice";
import Back from "@/components/back";
// import Features from "@/components/features";
// import Team from "@/components/team";
// import Contact from "@/components/contact";
// import Footer from "@/components/footer";

export default {
  components: {
    // SunriseIcon,
    // ArrowRightIcon,
    // Navbar,
    // Services,
    GetSercies,
    Back,
    // Features,
    // Team,
    // Contact
    // Footer
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <!-- <Navbar :navcolor="'light'" /> -->
    <div v-scroll-spy>
      <!-- Hero Start -->

      <!-- Hero Start -->
     <Back />
      <GetSercies />
      <Back />
      <!-- <Features /> -->
      <!-- <Pricing /> -->
      <!-- <Team /> -->
      <!-- <Blog /> -->
      <!-- <Contact /> -->
      <!-- <Footer /> -->
    </div>
  </div>
</template>