<script>
// import { BoxIcon} from "vue-feather-icons";

export default {
  // components: { BoxIcon },
  data() {
    return {
      title: "Telemedicine Services",
      description: "Complete a telemedicine visit, check your blood pressure, temperature, oxygen, and more. Right from this kiosk.",
      sampleService: [
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care on Demand",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Yale New Haven Health.",
          link: "https://www.ynhhs.org/"
        },
        {
          logo: require("@/assets/images/logos/teladoc.webp"),
          name: "Teladoc Virtual Care",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care 3",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care 4",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care 5",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care 6",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
        {
          logo: require("@/assets/images/logos/ynhhs.webp"),
          name: "Virtual Care 7",
          description: "Get access to your virtual care team 24/7. From highly trained professionals from Teladoc.",
          link: "https://www.teladoc.com/"
        },
       ],
    };
  },
  methods: {
    scrollTo(id) {
      this.$router.push(id);
    },
    URL(url) {
      window.location.href = url;
    },
    newTab (url) {
      window.open(url, '_blank');
    },
  }
  };
</script>

<template>
  <!-- Services start -->
  <!-- <section class="section" id="services"> -->
    <div class="container mt-5">
      <div class="row justify-content-center mb-5">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold">{{title}}</h2>
          <p class="text-muted">
            {{description}}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4" 
        v-for="(item, index) of sampleService"
          :key="index">
         <a @click="newTab(item.link)" >
          <div class="service-box text-center px-4 py-5 position-relative mb-4">
            <div class="service-box-content p-4">
              <div class="icon-mono service-icon avatar-md mx-auto mb-4">
                <img
                :src="item.logo"
                alt=""
                class=""
                height="60">
              </div>
              <h4 class="mb-3 font-size-22">{{item.name}}</h4>
              <p class="text-muted mb-0">
                {{item.description}}
              </p>
            </div>
          </div>
          </a>
        </div>
        <!-- end  -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->

  <!-- </section> -->
  <!-- Services end -->
</template>