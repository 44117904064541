<script>
import { ArrowLeftIcon } from "vue-feather-icons";
export default {
    components: {
    ArrowLeftIcon
  },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    },
    computed: {
        prevRoutePath() {return this.prevRoute ? this.prevRoute.path : '/'},
    }
}
</script>

<template>
<section class="mt-4" >
     <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 text-center">
          <h2 class="fw-bold"><router-link :to="prevRoutePath"  class="btn btn-secondary btn-lg"><arrow-left-icon class="icon-sm ms-1"></arrow-left-icon> Go Back</router-link></h2>
        </div>
      </div>
      </div>
</section>
</template>